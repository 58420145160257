import React from "react";
import scrollToElement from "scroll-to-element";
import Slider from "react-slick";
import { Fade } from "react-awesome-reveal";
import { Link } from "gatsby";

import { Layout } from "../components";
import FormLandingPages from "../components/FormLandingPages";
import QuickContactLandingPages from "../components/QuickContactLandingPages";
import locales from "../constants";

const process_content = [
  {
    title: "Briefing",
    text: "Zebranie informacji o celach i założeniach projektu. Idealnie, gdy dla marki została opracowana strategia komunikacji.",
  },
  {
    title: "Copywriting",
    text: "Zaprojektowanie nazw, haseł, opracowanie treści.",
  },
  {
    title: "Technologia",
    text: "Wybór technologii zadruku oraz rodzaju finalnego opakowania.",
  },
  {
    title: "Koncepcja",
    text: "Zebranie inspiracji i benchmarków, opracowanie koncepcji kreatywnych realizowanych projektów i KV.",
  },
  {
    title: "Design",
    text: "Zaprojektowanie logo, etykiet, opakowań, materiałów offline oraz digital pod zdefiniowane wcześniej technologię.",
  },
  {
    title: "Prezentacja",
    text: "Omówienie z Klientem przygotowanych projektów.",
  },
  {
    title: "Sesje poprawkowe",
    text: "Dopracowanie wybranej koncepcji w 2 sesjach poprawkowych.",
  },
  {
    title: "DTP",
    text: "Skład i przygotowanie do druku.",
  },
];

const PackagingPage = ({ pageContext: { slug } }) => {
  const lang = "pl";

  const slider_items = [
    {
      image: require("../assets/img/packaging/projekty-opakowan-hean.jpg"),
      href: "/projekty/hean/",
      alt: "Projekty opakowań dla firmy Hean",
    },
    {
      image: require("../assets/img/packaging/projekty-opakowan-akcesoriow-rowerowych.jpg"),
      href: "/projekty/intertec-polska/",
      alt: "Projekty opakowań akcesoriów rowerowych",
    },
    {
      image: require("../assets/img/packaging/projekt-opakowania-napoju-lewandowski.jpg"),
      href: "/projekty/lewandowski/",
      alt: "Projekt opakowania napoju Lewandowski",
    },
    {
      image: require("../assets/img/packaging/projekt-opakowania-suplement-diety.jpg"),
      href: "/projekty/naturlig/",
      alt: "Projekt opakowania na suplement diety NaturligFlex",
    },
    {
      image: require("../assets/img/packaging/projekt-refresh-opakowan-vega-cola.jpg"),
      href: "/projekty/vegan-cola/",
      alt: "Projekt refresh opakowań Vega Cola.jpg",
    },
    {
      image: require("../assets/img/packaging/projekt-etykiety-wodka-wschodnia.jpg"),
      href: "/projekty/wodka-wschodnia/",
      alt: "Projekt etykiety Wódka Wschodnia",
    },
    {
      image: require("../assets/img/packaging/projekty-etykiet-gg-brewery.jpg"),
      href: "/projekty/gg-brewery/",
      alt: "Projekty etykiet dla browaru GG Brewery",
    },
  ];

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Layout
      seo={{
        title: "Projekty opakowań i etykiet",
        href: slug,
        lang: "pl",
        // ogImage: require("../assets/img/synch/Synchronizatory_og_image.jpg"),
      }}
      header={{ icons: "#E9B941" }}
    >
      <section className="container-fluid pack__hero">
        <div className="row">
          <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <div className="pack__hero-content">
              <div className="row pack__hero-content--inner align-items-md-center">
                <div className="col-12 col-md-6">
                  <h1>
                    Projektowanie
                    <br />
                    <span>opakowań i etykiet</span>
                  </h1>
                  <p>
                    Jako agencja która od 2011 roku przygotowuje projekty
                    opakowań i etykiet rozumiemy, że opakowanie jest kluczowym
                    elementem prezentacji produktu. Pełni ono funkcje ochronne,
                    informacyjne i promocyjne. Aby dobrze zaprojektować
                    opakowanie lub etykietę, konieczne jest uwzględnienie
                    estetyki, ergonomii, formy, materiału, koloru i grafiki.
                  </p>
                  <div className="pack__hero-btn">
                    <button
                      type="button"
                      onClick={() => scrollToElement("#intro")}
                      className="btn_custom"
                    >
                      Dowiedz się więcej
                    </button>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <QuickContactLandingPages
                    heading="Porozmawiajmy o Twoim projekcie!"
                    locales={locales[lang]}
                    lang={lang}
                    landing_page="quick-form-projektowanie-opakowan-i-etykiet"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="scrollPortfolio"
          onClick={() => scrollToElement("#intro")}
          aria-label="Scroll to intro"
        />
      </section>

      <section className="container-fluid pack__intro" id="intro">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <h2 className="pack__intro-text">
            Jakie warunki powinien spełniać dobry projekt opakowania czy
            etykiety?
          </h2>
          <div className="row">
            <div className="col-12 col-xl-6">
              <div className="pack__intro-grid">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="pack__intro-grid-item">
                      <div className="pack__intro-grid-img">
                        <img
                          src={require("../assets/img/packaging/projektowanie-opakowan-ergonomia.jpg")}
                          alt="Projektowanie opakowań ergonomia"
                        />
                      </div>
                      <h3>Estetyka i ergonomia</h3>
                      <p>
                        To elementy wynikające z formy, materiału, koloru i
                        grafiki. Ważne są tutaj walory estetyczne jak i
                        logistyczne (transport, magazynowanie, manipulowanie).
                        Ściśle związane z formą jest także wielkość oraz kształt
                        opakowania.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="pack__intro-grid-item">
                      <div className="pack__intro-grid-img">
                        <img
                          src={require("../assets/img/packaging/projektowanie-opakowan-material.jpg")}
                          alt="Projektowanie opakowań dobór materiału"
                        />
                      </div>
                      <h3>Materiał</h3>
                      <p>
                        Należy uwzględnić funkcję ochronną, sprawdzić czy pod
                        wpływem temperatury, wilgoci, promieniowania świetlnego
                        materiał nie zmieni swoich właściwości lub dobrać taki
                        który za pomoca technologii zadruku wywoła pożadany
                        efekt.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="pack__intro-grid-item">
                      <div className="pack__intro-grid-img">
                        <img
                          src={require("../assets/img/packaging/projektowanie-opakowan-kolor.jpg")}
                          alt="Projektowanie opakowań wybór koloru"
                        />
                      </div>
                      <h3>Kolor</h3>
                      <p>
                        Decyzje o wyborze kolorystyki muszą być podejmowane ze
                        świadomością psychologicznego oddziaływania barw.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="pack__intro-grid-item">
                      <div className="pack__intro-grid-img">
                        <img
                          src={require("../assets/img/packaging/projektowanie-opakowan-grafika.jpg")}
                          alt="Projektowanie opakowań wybór grafiki"
                        />
                      </div>
                      <h3>Grafika</h3>
                      <p>
                        Elementem dopełniającym projekt opakowania jest grafika,
                        a więc typografia, elementy rysunku i fotografii.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 d-none d-xl-block">
              <div className="pack__intro-slim">
                <Fade direction="right" delay="30" triggerOnce="true">
                  <img
                    src={require("../assets/img/packaging/projekt-opakowania-slim.png")}
                    alt="Projekt opakowania napoju Slim"
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid px-0 pack__banner">
        <img
          src={require("../assets/img/packaging/projekt-etykiety-lewandowski.png")}
          alt="Projekt etykiety energy drink Lewandowski"
        />
      </div>

      <section className="container-fluid pack__steps">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <h2 className="pack__steps-text">Proces realizacji</h2>
          <p className="pack__steps-subtext">
            Nasz proces projektowania opakowań i etykiet jest skrupulatnie
            planowany i realizowany przez doświadczony zespół, który składa się
            z Grafika DTP, Technologa, Designerów, Copywritera oraz Project
            Managera.
          </p>
          <div className="row">
            <div className="col-xl-8">
              <div className="pack__steps-list">
                {process_content.map(({ title, text }, index) => {
                  return (
                    <div key={index} className="pack__steps-list-item">
                      <div className="pack__steps-list-number">
                        <h3>{index + 1}</h3>
                      </div>
                      <div className="pack__steps-list-content">
                        <h4>{title}</h4>
                        <p>{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-xl-4">
              <div className="pack__steps-images">
                <div className="pack__steps-image">
                  <img
                    src={require("../assets/img/packaging/projekt-etykiety-jagiello.png")}
                    alt="Projekt etykiety dla browaru Jagiełło"
                  />
                </div>
                <div className="pack__steps-image">
                  <img
                    src={require("../assets/img/packaging/projekt-etykiety-nadbuzanskie.png")}
                    alt="Projekt etykiety dla browaru Nadbużańskie"
                  />
                </div>
                <div className="pack__steps-image">
                  <img
                    src={require("../assets/img/packaging/projekt-opakowania-galaxy.png")}
                    alt="Projekt opakowania Galaxy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid pack__slider">
        <div className="pack__slider-top">
          <div className="pack__slider-top-box">
            <h2 className="pack__slider-top-text">
              Dowiedz się więcej na temat naszych realizacji w zakresie
              projektowania opakowań i etykiet
            </h2>
          </div>
        </div>
        <div className="pack__slider-wrapper">
          <Slider {...settings}>
            {slider_items?.map(({ image, href, alt }, index) => {
              return (
                <Link key={index} to={href} className="pack__slider-item">
                  <div className="pack__slider-img">
                    <img src={image} alt={alt} />

                    <div className="pack__slider-hover">
                      <div className="btn_custom">Zobacz więcej</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className="container-fluid pack__features">
        <div className="row pack__features--row1">
          <div className="col-12 col-lg-6">
            <div className="pack__features-content">
              <h3>Nasze specjalizacje</h3>
              <p>
                Nasza agencja specjalizuje się w projektowaniu opakowań i
                etykiet dla branży alkoholowej, farmaceutycznej, kosmetycznej
                oraz różnego rodzaju napojów. Mamy doświadczenie w tworzeniu
                unikalnych, estetycznych i efektywnych rozwiązań dla tych
                sektorów, które pomogły naszym klientom wyróżnić się na rynku i
                zwiększyć sprzedaż.
              </p>
              <p>
                Nasze doświadczenie nie kończy się jednak na tych branżach.
                Przez lata pracy z różnymi klientami stworzyliśmy szerokie
                portfolio obejmujące wiele różnych obszarów. Bez względu na to,
                w jakiej branży działasz, jesteśmy przekonani, że jesteśmy w
                stanie dostarczyć Ci rozwiązania, które pomogą Ci osiągnąć
                sukces.
              </p>
              <p>
                Chcielibyśmy podkreślić, że na naszej stronie jest tylko część
                naszych realizacji. Jeżeli chcesz poznać nasze pełne portfolio,
                skontaktuj się z nami aby pokazać Ci więcej naszych prac i
                omówić, jak możemy pomóc Twojej marce odnieść sukces.
              </p>
            </div>
          </div>
          <div className="px-0 col-12 col-lg-6">
            <Fade direction="right" delay="30" triggerOnce="true">
              <div className="pack__features-image">
                <Fade direction="right" delay="60" triggerOnce="true">
                  <img
                    src={require("../assets/img/packaging/projektowanie-etykiet-kolory.png")}
                    alt="Projektowanie opakowań i etykiet - wybór kolorów"
                  />
                </Fade>
              </div>
            </Fade>
          </div>
        </div>
        <div className="row pack__features--row2">
          <div className="px-0 col-12 col-lg-6 order-2 order-lg-1">
            <Fade direction="left" delay="30" triggerOnce="true">
              <div className="pack__features-image">
                <Fade direction="left" delay="60" triggerOnce="true">
                  <img
                    src={require("../assets/img/packaging/projekt-opakowania-kosmetykow.png")}
                    alt="Projekt opakowania kosmetyków"
                  />
                </Fade>
              </div>
            </Fade>
          </div>

          <div className="col-12 order-1 order-lg-2 col-lg-6">
            <div className="pack__features-content">
              <h3>Prezentacja Projektów</h3>
              <p>
                Staramy się, aby prezentacja projektów opakowań odbywała się na
                spotkaniu. Jeśli nie ma możliwości spotkania, prezentacja odbywa
                się podczas telekonferencji. Zależy nam na odpowiedniej
                prezentacji projektu oraz na jego omówieniu i zrozumieniu przez
                Klienta. Projekty są prezentowane na wydrukach, co jest najwyżej
                oceniane przez naszych Klientów
              </p>
            </div>
          </div>
        </div>
        <div className="pack__features--row3">
          <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <h3>Stała opieka nad marką</h3>
            <p>
              Po wykonaniu projektów proponujemy stałą opiekę projektową.
              Współpraca opiera się najczęściej o umowę ramową ze stałym
              cennikiem usług, dzięki czemu poza bardziej korzystnymi warunkami
              współpracy, dzięki znajomości brandu oraz dzięki pominięciu etapu
              każdorazowego wyceniania poszczególnych projektów skracamy czas
              ich realizacji
            </p>
            <div className="row pack__features--row3-gallery">
              <div className="col-12 col-sm-6">
                <div className="pack__features-gallery-img">
                  <img
                    src={require("../assets/img/packaging/projekt-etykiety-muszyna.jpg")}
                    alt="Projekt etykiety woda Muszyna Zdrój"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="pack__features-gallery-img">
                  <img
                    src={require("../assets/img/packaging/projekty-opakowan-letopic.jpg")}
                    alt="Projekty opakowań Letopic"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid px-0 pack__banner-2">
        <img
          src={require("../assets/img/packaging/projekt-opakowania-drdetox.png")}
          alt="Projekt opakowania butelki dr Detox"
        />
      </div>

      <section className="container-fluid pack__contact">
        <div className="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <h2 className="pack__contact-intro">Skontaktuj się z nami</h2>
          <p className="pack__contact-text">
            Jesteśmy przekonani, że nasza oferta projektowania opakowań i
            etykiet jest w stanie sprostać Twoim oczekiwaniom oraz potrzebom.
            Nasz doświadczony zespół specjalistów jest gotowy, aby pomóc Twojej
            marce osiągnąć sukces. Jeżeli jesteś zainteresowany naszymi
            usługami, chcesz dowiedzieć się więcej o tym, co możemy zaoferować,
            lub masz jakiekolwiek pytania, skontaktuj się z nami. Chętnie
            odpowiemy na wszystkie Twoje pytania i pomożemy Ci znaleźć najlepsze
            rozwiązania dla Twojej firmy.
          </p>

          <div className="pack__contact-form">
            <FormLandingPages
              locales={locales[lang]}
              lang={lang}
              landing_page="projektowanie-opakowan-i-etykiet"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PackagingPage;
