import "./styles.scss";

import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import Confetti from "react-dom-confetti";

import { Input, Check, Loader } from "../../components";

const QuickContactLandingPages = ({ locales, lang, landing_page, heading }) => {
  const { register, errors, handleSubmit, triggerValidation } = useForm({
    mode: "onChange",
  });
  const config = {
    angle: 90,
    spread: 100,
    startVelocity: 60,
    elementCount: 150,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 0,
    width: "7px",
    height: "10px",
    colors: [
      "#E68F17",
      "#FAB005",
      "#FA5252",
      "#E64980",
      "#BE4BDB",
      "#0B7285",
      "#15AABF",
      "#EE1233",
      "#40C057",
    ],
  };

  const legal = locales.audyt_form.audit_legal;
  const legal_short = locales.audyt_form.audit_legal.substring(0, 88);
  const legal_short_2 = locales.legal_third.substring(0, 88);

  const [isLoading, setLoading] = useState(false);
  const [showConfetti, triggerConfetti] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;
    data = { ...data };
    triggerConfetti(true);
    setTimeout(() => {
      setLoading(true);
      axios
        .post(
          "https://adream-mailing.herokuapp.com/send-form-ladning-pages",
          data
        )
        .then((res) => {
          setLoading(false);
          triggerConfetti(false);
          e.target.reset();
          navigate("/landing-pages-kontakt/");
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1500);
  };
  return (
    <div className={`quick-lp-form wpcf7 ${landing_page}`}>
      {isLoading && <Loader />}
      <h3 className="quick-lp-form__heading">
        {heading || "Porozmawiajmy o Twoim projekcie!"}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="checkbox"
          name="bot_field"
          ref={register}
          className="bot-field"
        />
        <Input
          type="text"
          name="landing_page"
          value={landing_page}
          register={register}
          hidden
        />
        {/* <div className="quick-lp-form__wrapper">
          <Input
            type="text"
            name="name"
            label={locales.name}
            register={register}
            validation={{
              required: locales.required,
              message: locales.email_error,
            }}
            errors={errors}
          />
        </div> */}
        <div className="quick-lp-form__wrapper">
          <Input
            type="text"
            name="company"
            label="Nazwa firmy"
            register={register}
            validation={{
              required: locales.required,
              message: locales.email_error,
            }}
            errors={errors}
          />
          <Input
            type="email"
            name="email"
            label="E-mail firmowy"
            register={register}
            validation={{
              required: locales.required,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                message: locales.email_error,
              },
            }}
            errors={errors}
          />
          <Input
            type="phone"
            name="phone"
            label={locales.phone}
            register={register}
            validation={{ required: locales.required }}
            errors={errors}
          />
        </div>
        <Check
          register={register}
          name="term1"
          size="sm"
          validation={{ required: locales.required }}
          errors={errors}
          label={
            showMore ? (
              legal
            ) : (
              <>
                {legal_short}
                <button
                  type="button"
                  className="quick-lp-form__show-more"
                  onClick={() => setShowMore(true)}
                >
                  Więcej{"  "}
                </button>
              </>
            )
          }
        />
        <Check
          register={register}
          name="term2"
          size="sm"
          errors={errors}
          label={
            showMore ? (
              legal
            ) : (
              <>
                {legal_short_2}
                <button
                  type="button"
                  className="quick-lp-form__show-more"
                  onClick={() => setShowMore(true)}
                >
                  Więcej{"  "}
                </button>
              </>
            )
          }
        />

        <div className="btn_over_wrapper">
          <div className="btn_wrapper">
            <input
              type="submit"
              value={locales.contact_form_send}
              className="wpcf7-form-control wpcf7-submit"
              onClick={() => {
                triggerValidation();
              }}
            />

            <Confetti active={showConfetti} config={config} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuickContactLandingPages;
